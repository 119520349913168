@import 'src/styles/constants.scss';
@import 'src/styles/mixins.scss';
@import 'src/styles/transitions.scss';

.card {
  position: relative;

  .tag {
    position: absolute;
    top: 24px;
    left: 24px;
    z-index: 1;
    @include breakpoint('mobile') {
      top: 16px;
      left: 16px;
    }
  }

  a,
  span,
  div {
    cursor: pointer;
  }

  a {
    display: block;
    text-decoration: none;
  }

  .imageWrapper {
    position: relative;
    overflow: hidden;
    background: $borderGray;
    border-radius: 12px;

    @include breakpoint('desktop') {
      border-radius: 20px;
    }
    &:before {
      content: '';
      display: block;
      width: 100%;
      padding-top: 132%;
    }

    img {
      border-radius: 12px;
      @include AbsoluteImage;
      @include breakpoint('desktop') {
        border-radius: 20px;
      }
    }

    .hoverImage {
      display: none;
    }

    @include breakpoint('desktop') {
      &:hover {
        .hoverImage {
          display: block;
        }
      }
    }
  }
  .name {
    @include SerifText;
    font-size: 19px;
    line-height: 1;
    @include breakpoint('mobile') {
      font-size: 17px;
    }
  }

  .priceWrapper {
    opacity: 0;
    transition: opacity 150ms linear;

    &.loaded {
      opacity: 1;
    }
  }

  .price,
  .sale,
  .old {
    @include SansSerifText;
  }
  .sale {
  }
  .old {
    text-decoration: line-through;
    color: rgba(17, 17, 17, 0.2);
  }

  .buttonWrapper {
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translateY(0%);
    opacity: 0;
    transition: transform 0.02s linear 0.12s, opacity 0.12s $easeOutCubic;

    @include breakpoint('mobile') {
      opacity: 1;
      transform: translateY(0);
    }

    button {
      background: $white;
      margin-left: 12px;
    }
  }

  .imageWrapper {
    @include breakpoint('desktop') {
      &:hover {
        .buttonWrapper {
          transition: transform 0.02s linear, opacity 0.12s $easeOutCubic 0.02s;
          opacity: 1;
          transform: translateY(0%);
        }
      }
    }
  }

  &.skinTypeProduct {
    @include breakpoint('desktop') {
      .imageWrapper {
        border-radius: 12px;
        img {
          border-radius: 12px;
        }
      }
      .name {
        font-size: 18px;
      }
      .tag {
        top: 16px;
        left: 16px;
      }
    }
  }

  &.ingredientProduct {
    .tag {
      top: 16px;
      left: 16px;
      span {
        font-size: 12px !important;
        height: 22px !important;
        border-radius: 4px;
        padding-top: 4px;
      }
    }

    .buttonWrapper {
      padding-right: 16px;
      padding-bottom: 16px;
    }

    .imageWrapper {
      border-radius: 12px;
      margin-bottom: 12px;

      img {
        border-radius: 12px;
      }

      @include breakpoint('mobile') {
        margin-bottom: 14px;
      }

      button {
        width: 26px;
        height: 26px;
        border-radius: 4px;

        &:hover {
          background: $mediumGray;
        }
        svg {
          width: 12px;
        }
      }
    }

    @include breakpoint('desktop') {
      .name {
        line-height: 1;
        font-weight: 300;
        font-size: 14px;
        letter-spacing: -0.02em;
        margin-bottom: 2px !important;
      }
      .price,
      .sale,
      .old,
      .outOfStock {
        font-weight: 370;
        font-feature-settings: 'ss05' on;
        line-height: 1.5;
        letter-spacing: -0.01em;
        font-size: 12px;
      }
    }

    @include breakpoint('mobile') {
      .name {
        font-size: 16px;
        font-weight: 300;
        line-height: 1;
        letter-spacing: -0.02em;
      }
      .price,
      .sale,
      .old,
      .outOfStock {
        font-feature-settings: 'ss05' on;
        font-size: 14px;
        font-weight: 370;
        line-height: 1.5;
        letter-spacing: -0.01em;
      }
    }
  }
}
