@use 'sass:math';

@mixin size($property, $pixels) {
  #{$property}: #{'clamp(#{$pixels}px, #{math.div($pixels, 16)}vw, 100vw)'};
}

// ======================================================================================
//  1. Helper functions
// ======================================================================================

@function map-deep-get($map, $keys...) {
  $value: $map;

  @each $key in $keys {
    $value: map-get($value, $key);
  }

  @return $value;
}

// ======================================================================================
//  2. Responsive
// ======================================================================================

@mixin breakpoint($breakpoint, $mode: null, $media: all) {
  //  @param string $breakpoint: Name of a pre-specified breakpoint, or a space separated list of pixel values to be used as min and max, or a single pixel value as max-width
  //  @param integer $mode: Optionally add "and-over" or "and-under"
  //  @param string $media: Apply only to a specific media type

  $breakpoints: (
    'mobile': (
      'min': 0px,
      'max': 1023px,
    ),
    'tablet': (
      'min': 768px,
      'max': null,
    ),
    'desktop': (
      'min': 1024px,
      'max': null,
    ),
    'laptop': (
      'min': 1279px,
      'max': null,
    ),
    'display': (
      'min': 1920px,
      'max': null,
    ),
    'xs': (
      // small mobile
      'min': 0px,
      'max': 374px,
    ),
    's': (
      // normal mobile
      'min': 375px,
      'max': 767px,
    ),
    'm': (
      // tablet portrait
      'min': 768px,
      'max': 1023px,
    ),
    'l': (
      // tablet landscape
      'min': 1024px,
      'max': 1099px,
    ),
    'xl': (
      // desktop
      'min': 1100px,
      'max': 1499px,
    ),
    'xxl': (
      // extra wide desktop
      'min': 1500px,
      'max': null,
    ),
  );

  // Defining default values
  $min: 0;
  $max: 100%;

  @if type-of($breakpoint) == list {
    // A list has been supplied, pixels or breakpoint names, use the first for min and second for max

    @if type-of(nth($breakpoint, 1)) == string {
      $min: map-deep-get($breakpoints, nth($breakpoint, 1), 'min');
    } @else {
      $min: nth($breakpoint, 1);
    }

    @if type-of(nth($breakpoint, 2)) == string {
      $max: map-deep-get($breakpoints, nth($breakpoint, 2), 'max');
    } @else {
      $max: nth($breakpoint, 2);
    }
  } @else if type-of($breakpoint) == string {
    // A prespecified breakpoint name was given
    $min: map-deep-get($breakpoints, $breakpoint, 'min');
    $max: map-deep-get($breakpoints, $breakpoint, 'max');

    @if $max == null and $mode == null {
      // If there's no max value set
      $mode: 'and-over';
    }
  } @else {
    // Number
    $min: $breakpoint;
    $max: $breakpoint;
  }

  // Output media query

  @if $mode == 'and-over' {
    @media only #{$media} and (min-width: $min) {
      @content;
    }
  } @else if $mode == 'and-under' {
    @media only #{$media} and (max-width: $max) {
      @content;
    }
  } @else if $mode == 'under' {
    @media only #{$media} and (max-width: #{$min - 1px}) {
      @content;
    }
  } @else if $mode == 'over' {
    @media only #{$media} and (min-width: #{$min + 1px}) {
      @content;
    }
  } @else {
    @media only #{$media} and (min-width: $min) and (max-width: $max) {
      @content;
    }
  }
}
@mixin AbsoluteImage() {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

// ======================================================================================
//  3. Typography settings
// ======================================================================================

@mixin WeightRegular() {
  font-variation-settings: 'wght' 370, 'GRAD' 88;
}

@mixin WeightBold() {
  font-variation-settings: 'wght' 570, 'GRAD' 88;
  font-weight: unset !important;
}

@mixin SerifLabel() {
  font-family: $fontSerif;
  font-size: 18px;
  line-height: 1em;
  letter-spacing: -0.02em;
}

@mixin SerifQuote() {
  font-family: $fontSerif;
  font-size: 52px;
  line-height: 1.2em;
  letter-spacing: -0.03em;

  @include breakpoint('mobile') {
    font-size: 28px;
    letter-spacing: -0.02em;
    line-height: 1.3em;
  }
}

@mixin SerifH1() {
  font-family: $fontSerif;
  font-size: 52px;
  line-height: 1.1em;
  letter-spacing: -0.03em;

  @include breakpoint('mobile') {
    font-size: 36px;
    letter-spacing: -0.02em;
    line-height: 1em;
  }
}

@mixin SerifHero() {
  font-family: $fontSerif;
  font-size: 80px;
  line-height: 1em;
  letter-spacing: -0.04em;

  @include breakpoint('mobile') {
    font-size: 36px;
    letter-spacing: -0.02em;
    line-height: 1em;
  }
}

@mixin SerifH2() {
  font-family: $fontSerif;
  font-size: 38px;
  line-height: 1.2em;
  letter-spacing: -0.02em;

  @include breakpoint('mobile') {
    font-size: 25px;
  }
}

@mixin SerifH3() {
  font-family: $fontSerif;
  font-size: 28px;
  line-height: 1.375em;
  letter-spacing: -0.02em;

  @include breakpoint('mobile') {
    font-size: 25px;
  }
}

@mixin SerifH4() {
  font-family: $fontSerif;
  font-size: 24px;
  line-height: 1.2em;
  letter-spacing: -0.02em;
}

@mixin SerifText() {
  font-family: $fontSerif;
  font-size: 18px;
  line-height: 1.32em;
  letter-spacing: -0.02em;
}

@mixin SansSerifH1() {
  @include WeightRegular();
  font-feature-settings: 'ss05' on;
  font-family: $fontSansSerif;
  font-size: 64px;
  line-height: 1.1em;
  letter-spacing: -0.03em;

  @include breakpoint('mobile') {
    font-size: 40px;
  }
}

@mixin SansSerifTitle() {
  @include WeightRegular();
  font-feature-settings: 'ss05' on;
  font-family: $fontSansSerif;
  font-size: 30px;
  line-height: 1.25em;
  letter-spacing: -0.01em;

  @include breakpoint('mobile') {
    font-size: 24px;
  }
}

@mixin SansSerifText() {
  @include WeightRegular();
  font-feature-settings: 'ss05' on;
  font-family: $fontSansSerif;
  font-size: 16px;
  line-height: 1.5em;
  letter-spacing: -0.01em;
}

@mixin SansSerifCTA() {
  @include WeightRegular();
  font-feature-settings: 'ss05' on;
  font-family: $fontSansSerif;
  font-size: 16px;
  line-height: 1.26em;
  letter-spacing: -0.01em;
}

@mixin SansSerifTextSmall() {
  @include WeightRegular();
  font-feature-settings: 'ss05' on;
  font-family: $fontSansSerif;
  font-size: 14px;
  line-height: 1.3em;
  letter-spacing: -0.01em;
}

@mixin CtaWithBorder() {
  @include SansSerifCTA;
  text-decoration: none;
  position: relative;
  white-space: nowrap;
  flex-shrink: 0;
  border-bottom: none;
  cursor: pointer;
  @include breakpoint('mobile') {
    font-size: 16px;
  }
  &:after {
    content: '';
    position: absolute;
    display: block;
    height: 1px;
    width: 100%;
    bottom: 0;
    background: $white;
    transition: transform 0.01s linear;
    transform-origin: 100% 50%;
  }
  &:before {
    position: absolute;
    bottom: 0;
    content: '';
    display: block;
    height: 1px;
    width: 100%;
    background: $white;
    transition: transform 0.01s linear;
    transform-origin: 0% 50%;
    transform: scaleX(0);
  }
  @include breakpoint('desktop') {
    &:hover {
      &:after {
        transform: scaleX(0);
        transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1);
      }
      &:before {
        transform: scaleX(1);
        transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1) 0.5s;
      }
    }
  }
}

@mixin InvertedCtaWithBorder() {
  @include SansSerifCTA;
  text-decoration: none;
  position: relative;
  white-space: nowrap;
  flex-shrink: 0;
  border-bottom: none;
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: $white;
    transform: scaleX(0);
    transform-origin: top left;
    transition: transform 0.1s linear;
  }
  @include breakpoint('desktop') {
    &:hover {
      &:before {
        transform: scaleX(1);
        transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1) 0s;
      }
    }
  }
}

@mixin AuthForm() {
  .title {
    @include SerifH2;
    @include breakpoint('mobile') {
      font-size: 32px;
    }
  }

  form {
    width: 100%;
  }

  button {
    @include breakpoint('mobile') {
      height: 56px;
    }
  }

  input {
    @include SerifText;
    height: 56px;
    border: 1px solid $black;
    border-radius: 10px;
    color: $black;
    padding: 2px 52px 0 24px;
    width: 100%;
    margin-bottom: 20px;

    &::placeholder {
      color: #aaa;
    }

    &:placeholder-shown {
      border-color: #ededed;
    }

    &[aria-invalid='true'] {
      border: 1px solid $red;
    }

    &:active,
    &:focus {
      border-color: $black;
      outline: none;

      &::placeholder {
        color: $black;
      }
    }

    // Css hack to remove autofill-background on Chrome
    &:-webkit-autofill,
    &:-webkit-autofill:focus {
      transition: background-color 600000s 0s, color 600000s 0s;
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px $white inset !important;
    }
    &[data-autocompleted] {
      background-color: transparent !important;
    }
  }
  .links {
    p {
      @include SerifText;
      font-size: 16px;
      line-height: 1.3em;
    }
    .link {
      @include CtaWithBorder;
      @include SerifText;
      &:after,
      &:before {
        background-color: $black;
      }
      text-decoration: none;
      font-size: 16px;
    }
  }

  .error {
    color: $red;
    margin-bottom: 20px;
    margin-top: -10px; // theres space underneath inputs
  }
  .confirmation {
    margin-bottom: 73px;
  }
}

@mixin DefaultActive() {
  transition: transform 120ms;
  &:active {
    transform: scale(0.8);
  }
}

@mixin hideScrollbar {
  &::-webkit-scrollbar {
    display: none;
    width: 0px;
    background: transparent; /* make scrollbar transparent */
  }

  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: none; /* Firefox 64 */
}
