@import 'src/styles/constants.scss';
@import 'src/styles/mixins.scss';
@import 'src/styles/transitions.scss';

.titleWrapper {
  @media (min-width: 1279px) {
    padding-left: 56px;
    padding-right: 56px;
  }
  @media (max-width: 1278px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.title {
  @include SerifH2;
  font-size: 36px;
  @media (max-width: 1278px) {
    font-size: 24px;
  }
  line-height: 1;
}

.hoverWrapper {
  position: relative;
  .nav {
    transition: opacity 0.15s ease-in-out;
    opacity: 0;
  }
  &:hover {
    .nav {
      opacity: 1;
    }
  }
}

.button {
  transition: opacity 0.15s ease-in-out;
  &.disabled {
    opacity: 0.3;
    cursor: default;
    &:before,
    &:after {
      background-color: transparent;
    }
  }
}

.container {
  height: 0;
  padding-bottom: 125%;
  overflow: hidden;
  position: relative;
  @media (max-width: 1278px) {
    display: none;
  }

  @media (min-width: 1279px) {
    padding-bottom: 43.4%;
    border-bottom-right-radius: 16px;
  }
}

.nav {
  position: absolute;
  width: 100%;
  padding-left: 82px;
  padding-right: 5.8%;
  left: 50%;
  top: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);
  pointer-events: none;

  button {
    pointer-events: all;
  }

  @media (max-width: 1278px) {
    display: none !important;
  }
}

.slides {
  height: 0;
  padding-bottom: 125%;
  @media (min-width: 1279px) {
    padding-bottom: 42%;
  }
  padding: 0 0 0 44px;
  position: relative;
  display: flex;
  // transition: all 0ms ease;

  & > div {
    flex-shrink: 0;
    width: 32%;
    height: 100%;

    img {
      position: absolute;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  img {
    border-radius: 20px !important;
  }
}

.link {
  a {
    @include SansSerifTextSmall;
    @include CtaWithBorder;

    &:before,
    &:after {
      background-color: $black;
    }
    text-decoration: none;
  }
}

.mobileContainer {
  @media (min-width: 1279px) {
    display: none !important;
  }
  @media (max-width: 1278px) {
    overflow-x: scroll;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;

    @include hideScrollbar;

    & > div {
      flex-shrink: 0;
      scroll-snap-align: center;

      &:last-child {
        // fix for safari to add some space to the right of the last item when scrolling to the end...
        position: relative;
        &::after {
          position: absolute;
          top: 0;
          content: '';
          right: -10px;
          width: 10px;
          height: 100%;
        }
      }
    }
  }
}
