@import 'src/styles/constants.scss';
@import 'src/styles/mixins.scss';

.button {
  border-radius: 8px;
  background: $borderGray;

  div {
    &:last-child {
      display: none;
    }
  }

  &.active {
    div {
      &:first-child {
        display: none;
      }
      &:last-child {
        display: flex;
      }
    }
  }

  @include breakpoint('desktop') {
    svg {
      transform: scale(1.01);
      // will-change: transform;
      transition: transform 0.18s linear;
    }
    &:hover {
      // background: $borderGray;
      svg {
        transform: scale(1.08);
      }
    }
  }

  &:hover {
  }
}

body {
  &:global(.theme-green) {
    .button {
      background: $lightGreen;
      svg path {
        stroke: $darkGreen;
      }

      &.active {
        svg path {
          fill: $darkGreen;
        }
      }
    }
  }
}
