// ======================================================================================
//  1. Colors
// ======================================================================================

$black: #202020;
$lightBlack: #202020;
$white: #ffffff;
$beige: #f5ead6;
$red: #e43232;

$serie-pink: #f5e7ea;
$serie-blue: #e3e6ee;
$serie-green: #e5eee4;
$serie-gray: #f4f4f4;
$serie-yellow: #fff9e4;

$lightGreen: #e1e6e0;
$darkGreen: #394338;

$lightGray: #f0f0f0;
$mediumGray: #f8f8f8;
$borderGray: #ededed;
$borderGrayOpaque: rgba(0, 0, 0, 0.075);
$darkGray: #d3d3d3;

$green: #0c8d4f;
$drawerMaxWidth: 480px;

// ======================================================================================
//  1. Fonts
// ======================================================================================

$fontSansSerif: 'ABC Diatype';
$fontSerif: 'Cheltenham';

$topDesktop: 66px;
$topMobile: 56px;
$topDesktopWithUsp: 103px;
$topMobileWithUsp: 88px;

$fullHeightDesktop: calc(100vh - 66px);
$fullHeightDesktopWithUSP: calc(100vh - 103px);

$fullHeightMobile: calc(100vh - 56px);
$fullHeightMobileWithUSP: calc(100vh - 88px);

// ======================================================================================
//  3. Other
// ======================================================================================

$transitionSlow: 0.6s;
$transitionMedium: 0.3s;
$transitionShort: 0.15s;
