@import 'src/styles/constants.scss';
@import 'src/styles/mixins.scss';

.custom404 {
  padding: 0 20px;
  .title {
    font-size: 40px;
    letter-spacing: -0.02em;
    font-family: $fontSerif;
    @include breakpoint('mobile') {
      font-size: 32px;
    }
  }
  .text {
    @include SansSerifText;
  }
}

.products {
  border-top: 1px solid $borderGray;
}
